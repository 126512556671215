<template>
  <div>
    <div class="DivBox">
      <p class="PTitle">构建合理的目标指标</p>
    </div>
    <div class="DivBox">
      <p class="PTitle LeftBor">服务介绍：</p>
      <p class="SmallP">
        通用SMART原则科学制定目标指标
        <br />
        形成有效的目标制约
      </p>
      <br />

      <p class="PTitle LeftBor">解决客户的什么问题：</p>
      <p class="SmallP">
        通过遵循SMART原则，可以确保目标指标明确、具体、可行，并且能够进行衡量和评估。
        <br />
        对关键绩效指标KPI、平衡计分卡BSC、目标分解法的灵活运用和有机结合，使目标指标的制定更具合理性
        <br />
        建立有效的目标方案，帮助企业进步，而不是成为基层员工的负担
      </p>
      <br />
      <p class="PTitle LeftBor">我们公司的解决方案：</p>
      <p class="SmallP">
        先培训后辅导
        <br />
        与相关责任人一起现场讨论目标指标的可行性
        <br />
        共同搭建目标方案
      </p>
      <br />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
@import "../../../../assets/style/FirstProductSon.css";
</style>